import { useEffect, useRef, useState } from 'react';
import serverErrorHandler from 'services/serverError.service';
import { SclaApplication } from 'features/scla/types';
import SignatureCanvas from 'react-signature-canvas';
import {
  openErrorToaster,
  openSuccessToaster,
} from 'services/toast.service';
import useAppNavigation from 'hooks/useAppNavigation';
import uploadSclaParentSignature from '../api/uploadSclaParentSignature';
import uploadSclaStudentSignature from '../api/uploadSclaStudentSignature';
import submitSclaApp from '../api/submitSclaApp';

export default function useUpdateSclaAppSignature(
  app: SclaApplication,
  setApplication: (val: SclaApplication) => void,
) {
  const { signatureInfo } = app;
  const { parentSignature, studentSignature } = signatureInfo;
  const [isDone, setIsDone] = useState(false);
  const [sectionsComplete, setSectionsComplete] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploadingParent, setIsUploadingParent] = useState(false);
  const [isUploadStudent, setIsUploadStudent] = useState(false);
  const parentSignatureRef = useRef<SignatureCanvas>(null);
  const studentSignatureRef = useRef<SignatureCanvas>(null);

  const { navToHome } = useAppNavigation();
  useEffect(() => {
    setIsDone(false);
    const {
      backgroundInfo,
      schoolInfo,
      familyInfo,
      promptInfo,
      transcriptsInfo,
    } = app;

    // check if all other sections are completed
    if (
      backgroundInfo.sectionComplete &&
      schoolInfo.sectionComplete &&
      familyInfo.sectionComplete &&
      promptInfo.sectionComplete &&
      transcriptsInfo.sectionComplete
    ) {
      setSectionsComplete(true);
    }

    if (parentSignature && parentSignature.url !== '') {
      if (parentSignatureRef.current) {
        parentSignatureRef.current.fromDataURL(parentSignature.url, {
          width: 500,
          height: 200,
        });
      }
    }

    if (studentSignature && studentSignature.url !== '') {
      if (studentSignatureRef.current) {
        studentSignatureRef.current.fromDataURL(studentSignature.url, {
          width: 500,
          height: 200,
        });
      }
    }

    setIsDone(true);
  }, [app]);

  const onClearParentSignature = () => {
    if (parentSignatureRef.current) {
      parentSignatureRef.current.clear();
    }
  };

  const onClearStudentSignature = () => {
    if (studentSignatureRef.current) {
      studentSignatureRef.current.clear();
    }
  };

  const onUploadParentSignature = async () => {
    try {
      if (parentSignatureRef.current) {
        const isEmpty = parentSignatureRef.current.isEmpty();

        if (!isEmpty) {
          setIsUploadingParent(true);
          const img = parentSignatureRef.current.toDataURL();

          // Form data payload for creating txter
          const formData = new FormData();

          // convert base64 image to Blob
          const blobRes = await fetch(img);
          const blob = await blobRes.blob();

          // add image blob to key pairs
          formData.append('file', blob);

          const response = await uploadSclaParentSignature(
            app._id,
            formData,
          );

          setApplication({
            ...app,
            signatureInfo: response.signatureInfo,
          });
          setIsUploadingParent(false);
          openSuccessToaster('Parent signature saved', 4000);
        } else {
          setIsUploadingParent(false);
          openErrorToaster('Please sign first.', 3000);
        }
      }
    } catch (e) {
      setIsUploadingParent(false);
      serverErrorHandler(e);
    }
  };

  const onUploadStudentSignature = async () => {
    try {
      if (studentSignatureRef.current) {
        const isEmpty = studentSignatureRef.current.isEmpty();

        if (!isEmpty) {
          setIsUploadStudent(true);
          const img = studentSignatureRef.current.toDataURL();

          // Form data payload for creating txter
          const formData = new FormData();

          // convert base64 image to Blob
          const blobRes = await fetch(img);
          const blob = await blobRes.blob();

          // add image blob to key pairs
          formData.append('file', blob);

          const response = await uploadSclaStudentSignature(
            app._id,
            formData,
          );

          setApplication({
            ...app,
            signatureInfo: response.signatureInfo,
          });
          setIsUploadStudent(false);
          openSuccessToaster('Student signature saved', 4000);
        } else {
          setIsUploadStudent(false);
          openErrorToaster('Please sign first.', 3000);
        }
      }
    } catch (e) {
      setIsUploadStudent(false);
      serverErrorHandler(e);
    }
  };

  const onSubmitApplication = async () => {
    if (parentSignature && studentSignature) {
      try {
        setIsSubmitting(true);
        const response = await submitSclaApp(
          app._id,
          app.backgroundInfo.email,
        );
        setApplication(response);
        openSuccessToaster('Application Submitted!', 4000);
        setIsSubmitting(false);
        navToHome();
      } catch (e) {
        setIsSubmitting(false);
        serverErrorHandler(e);
      }
    } else {
      openErrorToaster('Please sign first.', 3000);
    }
  };

  return {
    isDone,
    parentSignature,
    studentSignature,
    parentSignatureRef,
    studentSignatureRef,
    isUploadStudent,
    isUploadingParent,
    sectionsComplete,
    isSubmitting,
    onUploadStudentSignature,
    onUploadParentSignature,
    onClearParentSignature,
    onClearStudentSignature,
    onSubmitApplication,
  };
}
